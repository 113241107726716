<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              :to="{ name: 'List Bookings' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("BOOKINGS.BOOK_SPOT") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="../../../../../public/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKING_LIST") }}</span>
              </li>
              <li>
                <span>{{ $t("BOOKINGS.BOOK_SPOT") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <!-- shopping cart here -->
          <div class="shopping-cart">
            <a href="#">
              <span class="icon">
                <span class="count">0</span>
                <img
                  src="../../../../../public/img/shopping-cart.svg"
                  alt="shopping-cart"
                />
              </span>
              <span class="text">
                {{ $t("COMMON.SHOPPING_CART") }}
              </span>
            </a>
          </div>
        </div>
      </div>

      <booking-spot-list-table
        @onCreateBooking="openBookingCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isAddBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeBookingModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("BOOKINGS.ADD_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-booking-component
              v-if="selectedSpotId"
              :spotId="selectedSpotId"
              @onViewBooking="openBookingModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  Link,
  PageHeader,
  Breadcrumb,
  BreadcrumbItem,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import AddBookingComponent from "./components/AddBookingComponent.vue";
import BookingSpotListTable from "./partials/BookingSpotListTable";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    BookingSpotListTable,
    AddBookingComponent,
    [Button.name]: Button,
    [PageHeader.name]: PageHeader,
    [Link.name]: Link,
    [BreadcrumbItem.name]: BreadcrumbItem,
    [Breadcrumb.name]: Breadcrumb,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const bookingId = this.$route.query.id;
    const spotId = this.$route.query.spotId;
    const action = this.$route.query.action;
    let isViewBookingModalOpened = false;
    let isEditBookingModalOpened = false;
    let isAddBookingModalOpened = false;
    let openBooking = null;
    if (bookingId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewBookingModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditBookingModalOpened = true;
      }
      openBooking = { id: bookingId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddBookingModalOpened = true;
    }
    return {
      isViewBookingModalOpened: isViewBookingModalOpened,
      isEditBookingModalOpened: isEditBookingModalOpened,
      isAddBookingModalOpened: isAddBookingModalOpened,
      openBooking: openBooking,
      selectedSpotId: spotId,
      renderKey: 1,
    };
  },

  methods: {
    openBookingCreateModal(spotId) {
      this.selectedSpotId = spotId;
      this.closeBookingModal();
      this.isAddBookingModalOpened = true;

      history.pushState(
        { spot: spotId },
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { spotId: spotId, action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openBookingModal(booking, reRender = false) {
      this.closeBookingModal();
      this.openBooking = booking;
      this.isViewBookingModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: { id: this.openBooking.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    closeBookingModal() {
      this.isAddBookingModalOpened = false;
      this.isViewBookingModalOpened = false;
      this.isEditBookingModalOpened = false;
      this.openBooking = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "New Booking",
          query: {},
        }).href
      );
    },
    returnToListOfBookings() {
      console.log(
        this.$router.resolve({
          name: "List Bookings",
          query: {},
        }).href
      );
      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: {},
        }).href
      );
    },
  },
};
</script>
